$cell-size: 150px;
$max-width: 40rem;

.controls  {
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;

  h3 {
    margin: 0;
  }

  @media (max-width: 500px) {
    flex-direction: column
  }
}

.game-summary {
  max-width: $max-width;
  margin: 0 auto;
  margin-bottom: 1em;
}

.board {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 2px;
  grid-auto-rows: 1fr;
  margin: 0 auto;
  max-width: $max-width;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
}

.cell {
  position: relative;
  border: 1px solid #bbb;

}

.interactor-example {
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  margin-right: 0.5em;
  vertical-align: middle;
}

.interactor-one {
  background: url(./assets/images/oneCore.gif) no-repeat center center;
  background-size: cover;
}

.interactor-two {
  background: url(./assets/images/twoCore.gif) no-repeat center center;
  background-size: cover;
}

.interactor-three {
  background: url(./assets/images/threeCore.gif) no-repeat center center;
  background-size: cover;
}

.interactor-four {
  background: url(./assets/images/fourCore.gif) no-repeat center center;
  background-size: cover;
}

.interactor-reverse {
  background: url(./assets/images/reverse.gif) no-repeat center center;
  background-size: cover;
}

.interactor-arrow-left, .interactor-arrow-right, .interactor-arrow-left, .interactor-arrow-up  {
  background: url(./assets/images/arrowLeft.gif) no-repeat center center;
  background-size: cover;
}

.interactor-arrow-down {
  background: url(./assets/images/arrowDown.gif) no-repeat center center;
  background-size: cover;
}

.interactor-arrow-up {
  background: url(./assets/images/arrowUp.gif) no-repeat center center;
  background-size: cover;
}

.interactor-arrow-right {
  background: url(./assets/images/arrowRight.gif) no-repeat center center;
  background-size: cover;
}

.interactor-energizer {
  background: url(./assets/images/energizer.gif) no-repeat center center;
  background-size: cover;
}

.interactor-black-hole {
  background: url(./assets/images/blackHole.gif) no-repeat center center;
  background-size: cover;
}

.interactor-portal {
  background: url(./assets/images/portal.gif) no-repeat center center;
  background-size: cover;
}

.projectile-up, .projectile-down, .projectile-left, .projectile-right {
  background: url(./assets/images/projectile.gif) no-repeat center center;
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100%;
}

$projectile-scale: 0.5;

.projectile-up {
  transform: scale($projectile-scale);
}

.projectile-left {
  transform: rotate(270deg) scale($projectile-scale);
}

.projectile-right {
  transform: rotate(90deg) scale($projectile-scale);
}

.projectile-down {
  transform: rotate(180deg) scale($projectile-scale);
}
